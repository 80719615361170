import PropTypes from 'prop-types';
// icons
import {Icon} from '@iconify/react';
// @mui
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
};

interface IconifyProps {
  icon: React.ReactNode | string;
  sx?: object,
  width?: number,
  height?: number,
}

export default function Iconify({icon, sx, ...other}: IconifyProps) {

  return (
    <>
      {/* @ts-ignore */}
      <Box component={Icon} icon={icon} sx={{...sx}} {...other} />
    </>
  );
}
