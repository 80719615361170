import {Action} from 'redux';

export const DELETE_MEDIATHEQUE = 'DELETE_MEDIATHEQUE';
export const ADD_MEDIATHEQUE = 'ADD_MEDIATHEQUE';
export const SET_MEDIATHEQUE_LIST = 'SET_MEDIATHEQUE_LIST';


export interface Mediatheque {
  uuid?: string
  url?: string
  size?: number,
  name?: string
}

export interface Pagination {
  page?: number;
  per_page?: number;
  keyword?: string;
  category_uuid?: string;
  start_date?: string,
  end_date?: string,
  search_type?: string
}

export interface MediathequeResponse {
  total?: number;
  pages?: number;
  current_page?: number;
  per_page?: number;
  data: Mediatheque[];
}


export interface DeleteMediathequeAction extends Action {
  type: typeof DELETE_MEDIATHEQUE,
  uuid: string
}


export interface AddMediathequeAction extends Action {
  type: typeof ADD_MEDIATHEQUE,
  data: Mediatheque
}

export interface SetMediathequesListAction extends Action {
  type: typeof SET_MEDIATHEQUE_LIST,
  data: MediaState
}

export interface MediaState {
  pagination: Pagination
  response?: MediathequeResponse
}
