import BaseService from "./BaseService";
import {Pagination} from "store/types";

class MediaService {

  static add_mediatheque = (payload: any) => {
    // return BaseService.postRequest(MediathequeUrl.ADD_MEDIATHEQUE, payload, true);
  };
  static update_mediatheque = (payload: any) => {
    // return BaseService.putRequest(MediathequeUrl.UPDATE_MEDIATHEQUE, payload, true);
  };
  static delete_mediatheque = (uuid: string) => {
    // return BaseService.deleteRequest(MediathequeUrl.DELETE_MEDIATHEQUE(uuid), {}, true);
  };

  static get_mediatheque = (pagination: Pagination) => {
    // return BaseService.getRequest(MediathequeUrl.GET_MEDIATHEQUE(pagination), true);
  };
}


export default MediaService;
