import {Action} from "redux";
import { Supplier } from "store/types";
import {Category} from "./GlobalDataType";

export const CREATE_PRODUCT_CATEGORY_SUCCESS = "CREATE_PRODUCT_CATEGORY_SUCCESS";
export const READ_PRODUCT_CATEGORY_SUCCESS = "READ_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = "UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_SUCCESS = "DELETE_PRODUCT_CATEGORY_SUCCESS";

export const CREATE_PRODUCT_TAG_SUCCESS = "CREATE_PRODUCT_TAG_SUCCESS";
export const READ_PRODUCT_TAG_SUCCESS = "READ_PRODUCT_TAG_SUCCESS";
export const UPDATE_PRODUCT_TAG_SUCCESS = "UPDATE_PRODUCT_TAG_SUCCESS";
export const DELETE_PRODUCT_TAG_SUCCESS = "DELETE_PRODUCT_TAG_SUCCESS";

export const CREATE_PRODUCT_SUB_CATEGORY_SUCCESS = "CREATE_PRODUCT_SUB_CATEGORY_SUCCESS";
export const READ_PRODUCT_SUB_CATEGORY_SUCCESS = "READ_PRODUCT_SUB_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_SUB_CATEGORY_SUCCESS = "UPDATE_PRODUCT_SUB_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_SUB_CATEGORY_SUCCESS = "DELETE_PRODUCT_SUB_CATEGORY_SUCCESS";

export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const READ_PRODUCTS_SUCCESS = "READ_PRODUCTS_SUCCESS";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DISPLAY_PRODUCT_SUCCESS = "DISPLAY_PRODUCT_SUCCESS";
export const PUT_OR_REMOVE_PRODUCT_SUCCESS = "PUT_OR_REMOVE_PRODUCT_SUCCESS";
export const DELETE_IMAGE_PRODUCT_SUCCESS = "DELETE_IMAGE_PRODUCT_SUCCESS";
export const ADD_OR_REMOVE_PRODUCT_WHISHLIST_SUCCESS = "ADD_OR_REMOVE_PRODUCT_WHISHLIST_SUCCESS";
export const READ_PRODUCT_WHISHLIST_SUCCESS = "READ_PRODUCT_WHISHLIST_SUCCESS";

export const CREATE_SUPPLIER_SUCCESS = "CREATE_SUPPLIER_SUCCESS";
export const READ_SUPPLIER_SUCCESS = "READ_SUPPLIER_SUCCESS";
export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";


export interface ProductCategories {
  total: number;
  pages: number;
  current_page: number;
  per_page: number;
  data: ProductCategory[];
}
export interface ProductSuppliers {
  total: number;
  pages: number;
  current_page: number;
  per_page: number;
  data: Supplier[];
}

export interface ProductCategory {
  id?: number;
  title: Title;
  description?: Title;
  slug?: Title;
  total_product?: number;
  parent_id?: number;
  slug_id?: string;
  total_sub_category?: number;
  position?: number;
}

export interface ProductTags {
  total: number;
  pages: number;
  current_page: number;
  per_page: number;
  data: ProductTag[];
}

export interface ProductTag {
  id?: number;
  title: Title;
  description?: Title;
  slug?: Title;
  slug_id?: string;
}

export interface Title {
  en: string;
  fr: string;
}


export interface ProductsSubsCategories {
  total: number;
  pages: number;
  current_page: number;
  per_page: number;
  data: ProductSubCategory[];
}

export interface ProductsResponse {
  total: number;
  pages: number;
  current_page: number;
  per_page: number;
  data: ProductParams[];
}

export interface ProductSubCategory {
  title: Title;
  category?: {
    title: Title,
  },
  total_product?: number;
  position?: number;
  id?: number;
  category_id: number;
}

export interface ProductParams {
  uuid?: string;
  name?: LangI18N;
  description?: LangI18N;
  material_and_maintenance?: LangI18N;
  product_detail?: LangI18N;
  size_and_chopped_off?: LangI18N;
  files?: string[];
  code?: string;
  sku?: string;
  sizes?: string[];
  colors?: string[];
  categories?: ProductCategory[];
  tags?: ProductTag[];
  real_price?: number;
  current_price?: number;
  popularity_index?: number;
  is_online?: boolean;
  date_added?: string;
  date_modified?: string;
}

export interface ProductCategoryResponse {
  id?: number;
  title?: LangI18N;
  description?: LangI18N;
  slug_id?: string;
  slug?: LangI18N;
  parent_id?: number;
  child?: Category[];
  parent?: string;
}


export interface ProductPayload {
  uuid?: string;
  name?: LangI18N;
  description?: LangI18N;
  material_and_maintenance?: LangI18N;
  product_detail?: LangI18N;
  size_and_chopped_off?: LangI18N;
  files?: string[];
  code?: string;
  sku?: string;
  sizes?: string[];
  colors?: string[];
  categories?: number[];
  tags?: number[];
  real_price?: number;
  current_price?: number;
}


export interface ProductState {
  products: ProductsResponse,
  categories: ProductCategories,
  suppliers:ProductSuppliers,
  tags: ProductTags,
  subcategories: ProductsSubsCategories,
  product_detail: ProductParams | null
}


export interface CreateProductCategorySuccess extends Action {
  type: typeof CREATE_PRODUCT_CATEGORY_SUCCESS,
  category: ProductCategory,
}

export interface LangI18N {
  [key: string]: string
}


export interface ReadProductCategorySuccess extends Action {
  type: typeof READ_PRODUCT_CATEGORY_SUCCESS,
  categories: ProductCategories,
}

export interface UpdateProductCategorySuccess extends Action {
  type: typeof UPDATE_PRODUCT_CATEGORY_SUCCESS,
  category: ProductCategory,
}

export interface DeleteProductCategorySuccess extends Action {
  type: typeof DELETE_PRODUCT_CATEGORY_SUCCESS,
  id: number,
}


export interface CreateProductTagSuccess extends Action {
  type: typeof CREATE_PRODUCT_TAG_SUCCESS,
  tag: ProductTag
}

export interface ReadProductTagSuccess extends Action {
  type: typeof READ_PRODUCT_TAG_SUCCESS,
  tags: ProductTags,
}

export interface UpdateProductTagSuccess extends Action {
  type: typeof UPDATE_PRODUCT_TAG_SUCCESS,
  tag: ProductTag
}

export interface DeleteProductTagSuccess extends Action {
  type: typeof DELETE_PRODUCT_TAG_SUCCESS,
  id: number,
}

export interface CreateProductSubCategorySuccess extends Action {
  type: typeof CREATE_PRODUCT_SUB_CATEGORY_SUCCESS,
  sub_category: ProductSubCategory,
}

export interface ReadProductSubCategorySuccess extends Action {
  type: typeof READ_PRODUCT_SUB_CATEGORY_SUCCESS,
  sub_category: ProductsSubsCategories,
}

export interface UpdateProductSubCategorySuccess extends Action {
  type: typeof UPDATE_PRODUCT_SUB_CATEGORY_SUCCESS,
  sub_category: ProductSubCategory,
}

export interface DeleteProductSubCategorySuccess extends Action {
  type: typeof DELETE_PRODUCT_SUB_CATEGORY_SUCCESS,
  id: number,
}


export interface CreateProductSuccess extends Action {
  type: typeof CREATE_PRODUCT_SUCCESS,
  product: ProductParams
}

export interface ReadProductsSuccess extends Action {
  type: typeof READ_PRODUCTS_SUCCESS,
  products: ProductsResponse,
}

export interface UpdateProductSuccess extends Action {
  type: typeof UPDATE_PRODUCT_SUCCESS,
  product: ProductParams
}

export interface DeleteProductSuccess extends Action {
  type: typeof DELETE_PRODUCT_SUCCESS,
  uuid: string,
}

export interface DisplayProductSuccess extends Action {
  type: typeof DISPLAY_PRODUCT_SUCCESS,
  product: ProductParams | null,
}

export interface PutOrRemoveProductOnlineSuccess extends Action {
  type: typeof PUT_OR_REMOVE_PRODUCT_SUCCESS,
  id: number,
}

export interface DeleteIMageProductSuccess extends Action {
  type: typeof DELETE_IMAGE_PRODUCT_SUCCESS,
  id: number,
  fileuuid: string,
}

export interface AddorRemoveProductWhishlist extends Action {
  type: typeof ADD_OR_REMOVE_PRODUCT_WHISHLIST_SUCCESS,
  productuuid: string
}

export interface RedadProductWhishlist extends Action {
  type: typeof READ_PRODUCT_WHISHLIST_SUCCESS,
  productuuid: string
}

export interface CreateSupplierSuccess extends Action {
  type: typeof CREATE_SUPPLIER_SUCCESS,
  supplier: Supplier,
}

export interface ReadSupplierSuccess extends Action {
  type: typeof READ_SUPPLIER_SUCCESS,
  suppliers: ProductSuppliers,
}

export interface UpdateSupplierSuccess extends Action {
  type: typeof UPDATE_SUPPLIER_SUCCESS,
  supplier: Supplier,
}

export interface DeleteSupplierSuccess extends Action {
  type: typeof DELETE_SUPPLIER_SUCCESS,
  uuid: string,
}





