import produce from 'immer';
import {
  ADD_USER,
  ADD_USER_REQUEST,
  ADD_USER_REQUEST_ENDED,
  ApplicationAction,
  CHANGE_USER_STATUS, DELETE_USER,
  LOAD_USERS_ERROR,
  LOAD_USERS_REQUEST,
  LOAD_USERS_SUCCESS, UPDATE_USER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_REQUEST_ENDED,
  UsersState
} from 'store/types';

export const initialState: UsersState = {
  loading: {
    users: false
  },
  data: {
    data: [],
    pages: 1,
    total: 1,
    current_page: 1
  },
  range: {
    page: 1,
    per_page: 20,
    keyword: '',
  }
};

const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case LOAD_USERS_REQUEST:
      return produce(state, (draft) => {
        draft.loading.users = true;
      });
    case LOAD_USERS_SUCCESS:
      return produce(state, (draft) => {
        draft.loading.users = false;
        draft.data = action.data;
      });
    case LOAD_USERS_ERROR:
      return produce(state, (draft) => {
        draft.loading.users = false;
      });
    case ADD_USER_REQUEST:
      return produce(state, (draft) => {
        draft.loading.users = true;
      });
    case ADD_USER:
      return {
        ...state,
        data: {
          ...(state?.data || {}),
          data: [action.data.data, ...(state?.data?.data || [])]
        }
      }
    case CHANGE_USER_STATUS: {
      return {
        ...state,
        data: {
          ...state.data,
          data: [action.data.data, ...(state?.data?.data || [])]
        }
      }
    }
    case DELETE_USER: {
      return {
        ...state,
        data: {
          ...state.data,
          data: (state?.data?.data ?? []).filter((user) => user.uuid !== action.data.uuid)
        }
      }
    }
    case UPDATE_USER:
      return {
        ...state,
        data: {
          ...state.data,
          data: (state?.data?.data ?? []).map((user) => user.uuid !== action.data.uuid ? user : action.data)
        }
      }
    default:
      return state;
  }
};

export default reducer;
