import { mocked } from 'utils';
import { auth_users, connected_information } from 'services/fixtures';

export const login_mock = (info: any) => {

  const { email, password } = info;
  let auth_user = auth_users.find(
    (auth_user) =>
      auth_user.email === email && auth_user.password === password
  );
  return auth_user
    ? mocked(200, {
        Authorization: auth_user.Authorization,
        data: auth_user.data,
        message: auth_user.message
      })
    : mocked(401, { message: 'Login ou mot de passe invalide!' });
};

export const register_mock = (info: any) => {
  let data_is_valid =
    info.name && info.code_uai && info.academie_id >= 0 && info.referer;
  let school = {
    name: 'School Test',
    email: 'test@gmail.com',
    qualite: 'conseiller',
    phonenumber: '22415234',
    code_uai: '01020304'
  };

  if (!data_is_valid)
    return mocked(400, { message: 'Format de données invalides!' });
  else {
    let conflict =
      info.name === school.name ||
      info.referer.email === school.email ||
      info.code_uai === school.code_uai;

    return !conflict
      ? mocked(200, {
          message:
            "Nous avons reçu votre demande d'inscription, vérifiez votre boite mail pour avoir votre mot de passe temporaire!"
        })
      : mocked(400, { message: 'Un utilisateur avec cet email existe déjà' });
  }
};

export const update_password_mock = (info: any) => {
  if (info.current_password !== 'string')
    return mocked(400, {
      status: 'fail',
      message: 'Ancien mot de passe incorrect'
    });
  else {
    return mocked(200, {
      message: 'Mot de passe changé avec succès!',
      status: 'success'
    });
  }
};

export const initialize_reset_password = () => {
  return mocked(200, {
    status: 'success',
    message:
      'Consulter votre boite mail pour continuer le processus de réinitialisation de votre mot de passe'
  });
};

export const confirm_reset_password = () => {
  return mocked(200, {
    status: 'success',
    message:
      'Votre demande de changement de mot de passe est maintenant effectif'
  });
};

export const get_connected_user_info = () => {
  return mocked(200, connected_information);
};
