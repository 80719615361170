import produce from 'immer';
import {ApplicationAction} from 'store/types';
import {GlobalDataState} from 'store/types';
//cli_import_section
import {SET_GLOBAL_DATA} from 'store/types'


const initialState: GlobalDataState = {
  roles: [],
  groups: [],
  sites: [],
  'status': []
}


const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
//cli_use_cases_section
    case SET_GLOBAL_DATA: {
      return action.data.data
    }

    default: {
      return state;
    }
  }
};

export default reducer;
