import React from 'react';
import {makeStyles} from "@mui/styles";
import {Button, CircularProgress} from '@mui/material';
import {ButtonProps} from '@mui/material';
import clsx from 'clsx';
import {LoadingButton} from "@mui/lab";


export interface ButtonPrimaryProps extends ButtonProps {
    loading?: boolean;
    disabled?: boolean;
}

export default function ButtonPrimary(props: ButtonPrimaryProps) {

    return (
        <LoadingButton
            loadingIndicator={<CircularProgress size={24}/>}
            {...props} />
    );
}
