import * as React from 'react';
import {useState} from 'react';
import {Box, Chip, Menu, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {styled, useTheme} from "@mui/material/styles";
import {invertColor} from "../utils/color";
import {SketchPicker} from 'react-color'


interface ColorPickerProps {
  label?: string
  value?: any
  multiple?: boolean;
  onChange: (value: string[] | string) => void
}

const LabelStyle = styled(Typography)(({theme}) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export const ColorPicker = (props: ColorPickerProps) => {
  const {label, value, multiple, onChange} = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [colors, setColors] = useState(multiple ? (value ? value : []) : (value ? value : ""));

  const {t} = useTranslation();
  const theme = useTheme();

  const handleDelete = (index: number) => {
    const replicate = [...colors];
    if (index != -1) {
      replicate.splice(index, 1);
    }
    setColors(replicate);
    onChange(replicate);
  };


  return (
    <Box>
      <Stack spacing={2} direction={"row"} sx={{mb: 2}} alignItems={"center"}>
        <Box>
          <Box
            onClick={handleClick}
            id="color-picker-element"
            aria-labelledby="demo-positioned-button"
            sx={{
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '50%',
              cursor: "pointer",
              width: '32px',
              height: "32px"
            }}
          >
          </Box>
          <Menu
            id="color-picker-element"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <SketchPicker
              disableAlpha={true}
              color={theme.palette.primary.main}
              onChangeComplete={({hex: newColor}: any) => {
                if (multiple) {
                  setColors([newColor, ...colors]);
                  onChange([newColor, ...colors]);
                } else {
                  setColors(newColor);
                  onChange(newColor);
                }
                handleClose();
              }
              }
            />
          </Menu>
        </Box>
        <LabelStyle>
          {label}
        </LabelStyle>
      </Stack>
      <Box>

      </Box>
      {
        multiple && colors.length > 0 &&
        colors.map((item: string, index: number) => <Chip
          key={index}
          sx={{m: .5, backgroundColor: item, color: invertColor(item)}} label={item}
          onDelete={() => handleDelete(index)}
        />)
      }
      {
        !multiple && colors.length > 0 &&
          <Chip label={colors} sx={{backgroundColor: colors, color: invertColor(colors)}}/>
      }
    </Box>
  );
};

export default ColorPicker;
