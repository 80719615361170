//cli_import_section
import {SetGlobalDataAction, SET_GLOBAL_DATA, SetGlobalDataActionParams} from 'store/types'

export {}



//cli_action_section

export const setGlobalDataAction = (data: SetGlobalDataActionParams): SetGlobalDataAction => ({
   type: SET_GLOBAL_DATA,
   data
});