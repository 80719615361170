import {Locale} from 'moment';
import {Action} from 'redux';
import { TitleI18N} from "./AccountTypes";

//cli_export_interface_section
export const SET_GLOBAL_DATA = 'SET_GLOBAL_DATA';

export interface SetGlobalDataAction extends Action {
  type: typeof SET_GLOBAL_DATA,
  data: SetGlobalDataActionParams
}

export interface SetGlobalDataActionParams {
  data: GlobalDataState
}

export interface CategoriesWithSubCategory {
  sub?:      Category[];
  category?: Category;
}

export interface Category {
  title?: TitleI18N;
  id?:    number;
}




export interface GlobalDataState {
  roles?:  Role[];
  status?: string[];
  sites?:  Site[];
  groups?: Group[];
}

export interface Group {
  ceo_name?: string;
  uuid?:     string;
  name?:     string;
}

export interface Role {
  uuid?: string;
  name?: string;
  code?: string;
}

export interface Site {
  uuid?:             string;
  localisation?:     string;
  date_added?:       string;
  added_by_uuid?:    string;
  name?:             string;
  group_uuid?:       null;
  date_modified?:    string;
  modified_by_uuid?: null;
}
