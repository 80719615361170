import {Box, Link, Typography} from '@mui/material';

import Breadcrumbs from './Breadcrumbs';


interface HeaderBreadcrumbsTypes {
  links?: any;
  action?: any;
  heading: string;
  moreLink?: any;
  sx?: object
}

export default function HeaderBreadcrumbs(props: HeaderBreadcrumbsTypes) {
  const {
    links,
    action,
    heading,
    moreLink = '' || [],
    sx,
    ...other
  } = props;
  return (
    <Box sx={{mb: 5, ...sx}}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Box sx={{flexGrow: 1}}>
          <Typography variant="h4" gutterBottom>
            {heading}
          </Typography>
          <Breadcrumbs links={links} {...other} />
        </Box>

        {action && <Box sx={{flexShrink: 0}}>{action}</Box>}
      </Box>

      <Box sx={{mt: 2}}>
        {typeof moreLink === 'string' ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        ) : (
          (moreLink ?? []).map((href: any) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{display: 'table'}}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
