import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';

import {alpha} from '@mui/material/styles';
import {Box, Divider, MenuItem, Stack, Typography} from '@mui/material';

import useIsMountedRef from '../../../hooks/useIsMountedRef';

import MyAvatar from '../../../components/MyAvatar';
import MenuPopover from '../../../components/MenuPopover';
import {IconButtonAnimate} from '../../../components/animate';
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store/types";
import {logout} from "../../../store/actions/AccountActions";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Accueil',
    linkTo: '/app/home',
  },
  {
    label: 'Profil',
    linkTo: "/app/home",
  },
  {
    label: 'Paramètre',
    linkTo: "/app/profile",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();
  const history = useHistory();

  const {user} = useSelector((state: ApplicationState) => state.account);

  const isMountedRef = useIsMountedRef();

  const {enqueueSnackbar} = useSnackbar();

  const [open, setOpen] = useState(null);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      dispatch(logout());
      history.push('history')
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', {variant: 'error'});
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          //@ts-ignore
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme: any) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar/>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant="subtitle2" noWrap>
            {user?.firstname ?? ""} {user?.lastname ?? ""}
          </Typography>
          <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{borderStyle: 'dashed'}}/>

        <Stack sx={{p: 1}}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{borderStyle: 'dashed'}}/>

        <MenuItem onClick={handleLogout} sx={{m: 1}}>
          Se déconnecter
        </MenuItem>
      </MenuPopover>
    </>
  );
}
