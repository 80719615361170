export default {
  translation: {
    'shared.french': 'French',
    'shared.english': 'English',
    "field_require": "Field required",
    "login_page_action": "Login",
    "reset_page_action": "Receive the link",
    "reset_page_action_1": "Submit code",
    "reset_page_title":"Reset password",
    "reset_page_remember_title":"I remember my ID",
    "reset_page_description":"Enter the email associated with your account and we will send you a code to reset your password",
    "reset_page_description_1":"We've sent you a password reset code",
    "login_page_title": "Login",
    "login_form_email": "Email",
    "login_form_phone": "Phone",
    'shared.internet_connexion_error': 'An unknown error has occurred. Please try again later',
    "login_form_password": "Password",
    "login_form_require": "Required field",
    "unable_to_disconnect": "Unable to disconnect",
    "login_form_type_error": "Please enter a valid number",
    "login_page_subtitle": "Please complete the form below",
    "login_page_forgot_password": "Forgot password",
    "field_type_number_error": "Please enter a number",
    "navigation_item_users": "Users",
    "navigation_item_suppliers": "Suppliers",
    "error_message_page": "Page Not Found",
    "cause_error_not_found": "This page is under construction or does not exist",
    "go_back_to_home": "Back to Home",
    "topbar_profile_logout": 'Logout',
    "topbar_profile_profil": 'Profile',
    "review_dialog_action_next": 'Next',
    "review_dialog_action_preview": 'Previous',
    "email_form_invalid": 'Please enter a valid email address',
    "register_field_code": "Code",
    "home_page_title": "Boardboard",
    "label_add_user": "New User",
    "table_head_label_name": "Name",
    "table_head_label_email": "Email",
    "table_head_label_phonenumber": "Phone",
    "table_head_label_delivring_time": "Time of delivery",
    "table_head_label_address": "Address",
    "table_head_label_role": "Role",
    "table_head_label_status": "Status",
    "table_head_label_last_login": "Last Login",
    "user_status_UNVALIDATED": "Not validated",
    "user_status_ACTIVED": "Activated",
    "user_status_UNACTIVED": "Inactive",
    "user_status_DELETED": "Deleted",
    "global_action_edit": "Edit",
    "global_action_change": "Change status",
    "global_action_delete": "Delete",
    "global_label_all": "All",
    "global_label_search": "Searching...",
    "global_label_add_user": "New User",
    "global_label_add_supplier": "New supplier",
    "global_menu_item_dashboard": "Dashboard",
    "page_user_list": "User List",
    "page_supplier_list": "Suppliers list",
    "field_email_error": 'Please enter a valid email address',
    "choose_new_status_user": "Please select the new status",
    "admin_page_user_name": 'Name(s)',
    "admin_page_user_surname": 'Surname(s)',
    "admin_page_supplier_address": 'Address',
    "admin_page_user_email": 'Email',
    "add_user_modal_title": "New User",
    "update_user_modal_title": "Edit User USERNAME",
    "global_label_save": "Save",
    "global_label_cancel": "Cancel",
    "user_add_success": "User added successfully",
    "global_label_confirm": "Confirm",
    "delete_user_dialog_title": "Delete User",
    "change_user_dialog_title": "Change the status of a user",
    "dialog_confirm_change_users_statuts":"of this user",
    'dialog_confirm_delete_users': 'Are you sure you want to delete this user?',
    'dialog_confirm_delete_supplier': 'Do you really want to remove this supplier ',
    "user_delete_success": "User deleted",
    "empty_data_user_messages": "No user",
    "login_welcome_message": "Hello, welcome",
    "login_cta_title": "Login to Suits Men Paris",
    "login_cta_subtitle": "Enter your details below.",
    "user_update_success": "User updated",
    "menu_group_setting": "Settings",
    "menu_group_general": "General",
    "menu_item_media": "Media",
    "menu_item_products": "Products",
    "menu_item_categories": "Categories",
    "menu_item_supplier":"Suppliers",
    "menu_item_tags": "Tags",
    "menu_item_orders": "Orders",
    "menu_item_coupon_code": "Coupon Codes",
    "menu_item_app": "Dashboard",
    "menu_item_users": "Users",
    "menu_item_settings": "Settings",
    "menu_item_newsletters": "Newsletters",
    "menu_item_statistics": "Statistics",
    "global_action_update": "Update",
    "global_action_add": "Add",
    "asset_drop_zone_label": "Please choose a file.",
    "asset_drop_zone_label_mediatheque": "Please choose a file.",
    "asset_drop_zone_file_error": 'Please choose an image in JPEG or PNG mat format.',
    "dialog_confirmation": 'Confirmation',
    "dialog_action_confirm": 'Confirm',
    "dialog_action_cancel": 'Cancel',
    "dialog_confirm_change_mediatheque": "Are you sure you want to remove this item from the media library?",
    "menu_item_sub_categories": "Sub Categories",
    "add_category": "Add Category",
    "add_sub_category": "Add Category",
    "category": "Category",
    "category_page_select_parent_category": "Parent Category",
    "category_name":"Name",
    "category_description": "Description",
    "category_description_fr": "Description in French",
    "category_description_en": "Description in English",
    "category_slug": "Slug",
    "category_total": "Numbers produced",
    "category_sub_category_total": "Subcategories",
    "category_title_fr": "French title",
    "category_title_en": "English title",
    "create_category":"Create Category",
    "category_id": "ID",
    "update_category": "Update category CATEGORY_NAME",
    "update_sub_category": "Update SUB_CATEGORY_NAME subcategory",
    "delete_category_dialog_title": "Delete Category",
    "dialog_confirm_delete_category": "Are you sure you want to delete the category CATEGORY_NAME",
    "sub_category_name":"Name",
    "sub_category_description": "Description",
    
    "sub_category_description_fr": "Description in French",
    "sub_category_description_en": "Description in English",
    "sub_category_slug": "Slug",
    "sub_category_total": "Numbers produced",
    "sub_category_sub_category_total": "Sub categories",
    "sub_category_title_fr": "French title",
    "sub_category_title_en": "English title",
    "create_sub_category":"Create subcategory",
    "sub_category_id": "ID",
    "delete_sub_category_dialog_title": "Delete Sub Category",
    "dialog_confirm_delete_sub_category": "Are you sure you want to delete the SUB_CATEGORY_NAME subcategory",
    "category_list_is_empty": "No category at this time",
    "homepage_welcome_message": "Welcome!",
    "homepage_welcome_statics": "Last week sales are up PERCENT%.",
    "homepage_see_orders": "See Orders",
    "homepage_new_product": "New",
    "homepage_see_product": "Show",
    "homepage_product_sold": "Product Sold",
    "homepage_than_last_week": "than last week",
    "homepage_total_balance": "Total Balance",
    "homepage_sales_profit": "Sales Profit",
    "homepage_sale_by_gender": "Sale by gender",
    "homepage_mens": "Men",
    "homepage_womens": "Woman",
    "homepage_yearly_sales": "Annual Sales",
    "homepage_yearly_sales_revenu": "Sales Revenue",
    "homepage_than_last_year": "than last year",
    "product_page_title": "Product List",
    "product_page_add_new": "New product",
    "product_page_search_product": "Search Product...",
    "product_page_name": "Name",
    "product_page_code": "Code",
    "product_page_create_at": "Create Date",
    "product_page_status": "Status",
    "product_page_price": "Price",
    "product_page_edit": "Edit",
    "product_page_delete": "Delete",
    "product_page_rows_per_page": "Products per page",
    "users_page_rows_per_page": "Users per page",
    "product_page_dense": "Densify",
    "product_page_status_sale": "On Sale",
    "add_tag": "Add Tag",
    "add_sub_tag": "Add Tag",
    "tag_page_select_parent_tag": "Parent Tag",
    "tag_name":"Name",
    "tag_description": "Description",
    "tag_description_fr": "Description in French",
    "tag_description_en": "Description in English",
    "tag_slug": "Slug",
    "tag_total": "Numbers produced",
    "tag_sub_tag_total": "Sub tag",
    "tag_title_fr": "French title",
    "tag_title_en": "English title",
    "create_tag":"Create tag",
    "tag_id": "ID",
    "update_tag": "Update TAG_NAME tag",
    "delete_tag_dialog_title": "Delete Tag",
    "dialog_confirm_delete_tag": "Are you sure you want to delete the TAG_NAME tag",
    "tag_list_is_empty": "No tag at this time",
    "supplier_delivring_time":"Day(s)",
    "supplier_delivring_hours":"Hour(s)"

  }
};
