// @flow
import * as React from 'react';
import {useCallback, useState} from 'react';
import axios from 'axios'
import {makeStyles} from "@mui/styles";
import {Box, FormHelperText, IconButton, LinearProgress, Theme, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import {useDropzone} from "react-dropzone";
import clsx from "clsx";
import {FilePlus, Repeat} from "react-feather";
import {useTranslation} from "react-i18next";
import {toBase64} from "../utils/toBase64";
import SecureLS from "secure-ls";

const ls = new SecureLS({encodingType: 'aes'});

type Props = {
  file_types?: string[]
  label?: string,
  error?: string,
  data?: {
    url: string
    file_name: string
  }
  onChange: (data: Props["data"]) => void
};


const useStyles = makeStyles((theme: Theme) => ({
  dropZone: {
    border: `2px dashed #DDDDDD`,
    padding: '16px 16px',
    outline: 'none',
    position: 'relative',
    height: '100%',
    borderRadius: 5,
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      // backgroundColor: theme.palette.action.hover,
      // opacity: 0.5,
      cursor: 'pointer'
    }
  },
  dragActive: {
    border: `2px dashed ${theme.palette.action.active}`,
    // opacity: 0.5
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    position: 'absolute',
    opacity: .4,
    zIndex: 1,
    borderRadius: 5,
  },
}));

export const FileUploader = (props: Props) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const classes = useStyles();
  const {t} = useTranslation();
  const [error, setError] = useState<File | undefined>();
  const matchFileTypes = (type: string) => (props.file_types || []).includes(type);
  let lang = ls.get('lang') || 'fr';
  let token = ls.get('token') ? (ls.get('token') || '').toString() : '';

  const isFileImageName = (name?: string) => {
    return ['jpg', 'jpeg', 'svg', 'png']
      .includes((name || '').split('.').slice(-1)[0]?.toLowerCase())
  }

  const [src, setSrc] = useState<string | undefined>(isFileImageName(props.data?.url || '') ? props?.data?.url : undefined);

  const {enqueueSnackbar} = useSnackbar();


  const handleUpload = async (file: File) => {
    setUploading(true);
    setError(undefined);
    const base_64 = await toBase64(file);
    const payload = {
      base_64,
      file_name: file.name
    }

  }


  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {

      if (acceptedFiles.findIndex((file) => !matchFileTypes(file.type)) !== -1) {
        enqueueSnackbar(t('file_dropzone_error'), {
          variant: 'warning'
        });
      } else if (acceptedFiles.length > 0) {
        handleUpload(acceptedFiles[0])
        if (isFileImageName(acceptedFiles[0].name)) {
          setSrc(URL.createObjectURL(acceptedFiles[0]));
        } else {
          setSrc(undefined);
        }
      }
    },
    [props.onChange]
  );
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: handleDrop
  });

  return (
    <div
      className={clsx({
        [classes.dropZone]: true,
        [classes.dragActive]: isDragActive
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()}/>
      {src && <img src={src}
                   className={classes.img}
                   alt=""/>}
      <Box component={'div'} textAlign={'center'} zIndex={4} position={'relative'} width={'100%'}>
        <React.Fragment>
          {!src && <FilePlus size={64} color={'rgb(134, 137, 152)'} strokeWidth={1}/>}
          {!src && <Typography variant="body1" gutterBottom color={'textSecondary'} sx={{
            wordWrap: 'break-word'
          }}>
            {props.data?.file_name || props.label || t('file_dropzone_placeholder')}
          </Typography>}
          <Box p={2} sx={{
            // display: uploading ? 'flex' : 'none',
            // alignItems: 'center',
            width: '100%'
          }}>
            <LinearProgress
              value={progress}
              // sx={{
              //     flexGrow: 1
              // }}
              variant={'determinate'}/>
            {/*<IconButton sx={{ml: 2, mr: 2}} color={'primary'} onClick={(e) => {*/}
            {/*    e.stopPropagation();*/}
            {/*    setUploading(false);*/}
            {/*    setProgress(0);*/}
            {/*    source.cancel('Operation canceled by the user.');*/}
            {/*}}>*/}
            {/*    <X/>*/}
            {/*</IconButton>*/}
          </Box>
          {
            !uploading && error && <IconButton onClick={(e) => {
              e.stopPropagation();
              handleUpload(error);
            }} color={'primary'}>
                  <Repeat/>
              </IconButton>
          }
        </React.Fragment>
      </Box>
      {
        props.error && <FormHelperText error>
          {uploading ? t('error_pending_upload') : error ? ('error_finish_upload') : props.error}
          </FormHelperText>
      }
    </div>
  );
};
