// @flow
import * as React from 'react';
import {Autocomplete} from "@mui/lab";
import {TextField, Box} from "@mui/material";
import {MultiSelectOption} from "./FormBuilder";
import {useState} from "react";
import {useTranslation} from "react-i18next";

type AutocompleteMultipleSoloProps = {
  error?: boolean
  helperText?: string
  label?: string
  value: string[]
  onChange: (value: string[]) => void
};

export const AutocompleteMultipleSolo = (props: AutocompleteMultipleSoloProps) => {

  const values = props.value || [];

  const setValues = props.onChange

  const [inputValue, setInputValue] = useState('');
  const {t} = useTranslation();


  return (
    <Box>
      <Autocomplete
        noOptionsText={<>{t('validate_choice_with_enter')}</>}
        options={[] as string[]}
        sx={{
          '& svg': {
            fill: '#fff'
          },
        }}
        multiple
        onInputChange={(e, v) => setInputValue(v)}
        value={values}
        getOptionLabel={(e) => e}
        onChange={(e: any, value) => {
          setValues(value);
        }}
        renderInput={(params) => (
          <TextField
            label={props.label}
            helperText={props.helperText}
            error={props.error}
            {...params}
            InputProps={{
              ...params.InputProps,
              onKeyDown: (e) => {
                e.persist();
                if (e.key === 'Enter') {
                  setValues([inputValue, ...values]
                    .filter((i, k, s) => s.indexOf(i) === k))
                }
              }
            }}
            // label={field.label}
            variant={'outlined'}
          />
        )}
      />
    </Box>
  );
};
