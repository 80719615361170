export default {
  translation: {
    'shared.french': 'Francais',
    'shared.english': 'Anglais',
    "field_require": "Champ requis",
    "login_page_action": "Sign in",
    "reset_page_action": "Recevoir le lien",
    "reset_page_action_1": "Submit code",
    "reset_page_title":"Reset password",
    "reset_page_remember_title":"I remember my ID",
    "reset_page_description":"Enter the email associated with your account and we will send you a code to reset your password",
    "reset_page_description_1":"We've sent you a password reset code",
    "login_page_title": "Connexion",
    "login_form_email": "Email",
    "login_form_phone": "Téléphone",
    "login_page_welcome": "Welcome back !",
    "login_page_subtitle": "Sign in to continue.",
    "validate_choice_with_enter": "Appuyer sur entrée pour ajouter une valeur",
    'shared.internet_connexion_error': 'An unknown error has occurred. please try again later',
    "login_form_password": "Mot de passe",
    "login_form_require": "Champ requis",
    "unable_to_disconnect": "Impossible de se déconnecter",
    "login_form_type_error": "Veuillez entrer un numéro valide",
    "login_page_forgot_password": "Forgot your password",
    "field_type_number_error": "Veuillez saisir un nombre",
    "navigation_item_users": "Utilisateurs",
    "navigation_item_suppliers": "Fournisseurs",
    "error_message_page": "Page Introuvable",
    "cause_error_not_found": "Cette page est en cours de construction  ou n'existe pas",
    "go_back_to_home": "Retour à l'accueil",
    "topbar_profile_logout": 'Déconnexion',
    "topbar_profile_profil": 'Profil',
    "review_dialog_action_next": 'Suivant',
    "review_dialog_action_preview": 'Précédent',
    "email_form_invalid": 'Veuillez saisir une adresse email valide',
    "register_field_code": "Code",
    "home_page_title": "Tableau de board",
    "label_add_user": "Nouvel utilisateur",
    "table_head_label_name": "Nom",
    "table_head_label_email": "Email",
    "table_head_label_phonenumber": "Téléphone",
    "table_head_label_address": "Adresse",
    "table_head_label_delivring_time": "Delai de livraison",
    "table_head_label_role": "Role",
    "table_head_label_status": "Status",
    "table_head_label_last_login": "Dernière connexion",
    "user_status_UNVALIDATED": "Non validé",
    "user_status_ACTIVED": "Activé",
    "user_status_UNACTIVED": "Inactif",
    "user_status_DELETED": "Supprimé",
    "global_action_edit": "Modifier",
    "global_action_change": "Changer de status",
    "global_action_delete": "Supprimer",
    "global_label_all": "Tous",
    "global_label_search": "Recherche...",
    "global_label_add_user": "Nouvel utilisateur",
    "global_label_add_supplier": "Nouveau fournisseur",
    "global_menu_item_dashboard": "Tableau de bord",
    "page_user_list": "Liste des utilisateurs",
    "page_supplier_list": "Liste des fournisseurs",
    "field_email_error": 'Veuillez saisir une adresse mail valide',
    "admin_page_user_name": 'Nom(s)',
    "admin_page_user_surname": 'Prénom(s)',
    "admin_page_supplier_address": 'Adresse',
    "admin_page_user_email": 'Email',
    "add_user_modal_title": "Nouvel utilisateur",
    "update_user_modal_title": "Modifier l'utilisateur USERNAME",
    "update_suppler_modal_title": "Modifier le fournisseur USERNAME",
    "global_label_save": "Enregister",
    "global_label_cancel": "Annuler",
    "user_add_success": "Utilisateur ajouté avec succès",
    "global_label_confirm": "Confirmer",
    "delete_user_dialog_title": "Supprimer un utilisateur",
    "change_user_dialog_title": "Changer le statut d'un utilisateur",
    'dialog_confirm_delete_users': 'Souhaitez-vous vraiment supprimer cet utilisateur ?',
    'dialog_confirm_delete_supplier': 'Souhaitez-vous vraiment supprimer ce fournisseur ?',
    'dialog_confirm_change_users': 'Souhaitez-vous vraiment changer le status',
    "dialog_confirm_change_users_statuts": "de cet utilisateur",
    "user_delete_success": "Utilisateur supprimé",
    "empty_data_user_messages": "Aucun utilisateur",
    "login_welcome_message": "Bonjour, bienvenue",
    "login_cta_title": "Se connecter à Suits Men Paris",
    "login_cta_subtitle": "Entrez vos coordonnées ci-dessous.",
    "user_update_success": "Utilisateur mis à jour",
    "menu_group_setting": "Paramètres",
    "menu_group_general": "Général",
    "menu_item_media": "Médias",
    "menu_item_products": "Produits",
    "menu_item_categories": "Catégories",
    "menu_item_supplier":"Fournisseurs",
    "menu_item_tags": "Etiquettes",
    "menu_item_orders": "Commandes",
    "menu_item_coupon_code": "Codes promo",
    "menu_item_app": "Tableau de bord",
    "menu_item_users": "Utilisateurs",
    "menu_item_settings": "Paramètres",
    "menu_item_newsletters": "Newsletters",
    "menu_item_statistics": "Statistiques",
    "global_action_update": "Mettre à jour",
    "global_action_add": "Ajouter",
    "asset_drop_zone_label": "Veuillez choisir un fichier.",
    "asset_drop_zone_label_mediatheque": "Veuillez choisir un fichier.",
    "choose_new_status_user": "Veuillez choisir le nouveau status",
    "asset_drop_zone_file_error": 'Veuillez choisir une image au for  mat JPEG ou PNG.',
    "dialog_confirmation": 'Confirmation',
    "dialog_action_confirm": 'Confirmer',
    "dialog_action_cancel": 'Annuler',
    "dialog_confirm_change_mediatheque": "Voulez vous vraiment supprimer cette élément de la médiathèque ?",
    "menu_item_sub_categories": "Sous catégories",
    "add_category": "Ajouter une catégorie",
    "add_sub_category": "Ajouter une catégorie",
    "category": "Catégorie",
    "category_page_select_parent_category": "Catégorie parente",
    "category_name": "Nom",
    "category_description": "Description",
    "category_description_fr": "Description en français",
    "category_description_en": "Description en anglais",
    "category_slug": "Slug",
    "category_total": "Nombres produits",
    "category_sub_category_total": "Sous categories",
    "category_title_fr": "Titre français",
    "category_title_en": "Titre anglais",
    "create_category": "Créer la catégorie",
    "category_id": "ID",
    "update_category": "Modifier la catégorie CATEGORY_NAME",
    "update_sub_category": "Modifier la sous catégorie SUB_CATEGORY_NAME",
    "delete_category_dialog_title": "Supprimer la catégorie",
    "dialog_confirm_delete_category": "Êtes vous sûrs de vouloir supprimer la catégorie CATEGORY_NAME",
    "sub_category_name": "Nom",
    "sub_category_description": "Description",
    "sub_category_description_fr": "Description en français",
    "sub_category_description_en": "Description en anglais",
    "sub_category_slug": "Slug",
    "sub_category_total": "Nombres produits",
    "sub_category_sub_category_total": "Sous categories",
    "sub_category_title_fr": "Titre français",
    "sub_category_title_en": "Titre anglais",
    "create_sub_category": "Créer la sous catégorie",
    "sub_category_id": "ID",
    "delete_sub_category_dialog_title": "Supprimer la sous catégorie",
    "dialog_confirm_delete_sub_category": "Êtes vous sûrs de vouloir supprimer la sous catégorie SUB_CATEGORY_NAME",
    "category_list_is_empty": "Aucune catégorie pour le moment",
    "homepage_welcome_message": "Bienvenue!",
    "homepage_welcome_statics": "Les ventes sur la dernière semaine ont progressé de PERCENT%.",
    "homepage_see_orders": "Voir les commandes",
    "homepage_new_product": "Nouveau",
    "homepage_see_product": "Afficher",
    "homepage_product_sold": "Produit vendu",
    "homepage_than_last_week": "que la semaine dernière",
    "homepage_total_balance": "Solde total",
    "homepage_sales_profit": "Bénéfice des ventes",
    "homepage_sale_by_gender": "Vente par catégorie",
    "homepage_mens": "Vestes",
    "homepage_womens": "Accessoires",
    "homepage_yearly_sales": "Ventes annuelles",
    "homepage_yearly_sales_revenu": "Revenu de vente",
    "homepage_than_last_year": "que l'année dernière",
    "product_page_title": "Liste des produits",
    "product_page_add_new": "Nouveau produit",
    "product_page_search_product": "Rechercher un  produit...",
    "product_page_name": "Nom",
    "product_page_code": "Code",
    "product_page_create_at": "Date de création",
    "product_page_status": "Statut",
    "product_page_price": "Prix",
    "product_page_edit": "Editer",
    "product_page_delete": "Supprimer",
    "product_page_rows_per_page": "Produits par page",
    "users_page_rows_per_page": "Utilisateurs par page",
    "product_page_dense": "Densifier",
    "product_page_status_sale": "En vente",
    "add_tag": "Ajouter une étiquette",
    "add_sub_tag": "Ajouter une étiquette",
    "tag_page_select_parent_tag": "Etiquette parente",
    "tag_name": "Nom",
    "tag_description": "Description",
    "tag_description_fr": "Description en français",
    "tag_description_en": "Description en anglais",
    "tag_slug": "Slug",
    "tag_total": "Nombres produits",
    "tag_sub_tag_total": "Sous étiquette",
    "tag_title_fr": "Titre français",
    "tag_title_en": "Titre anglais",
    "create_tag": "Créer l'étiquette",
    "tag_id": "ID",
    "update_tag": "Modifier l'étiquette TAG_NAME",
    "delete_tag_dialog_title": "Supprimer l'étiquette",
    "dialog_confirm_delete_tag": "Êtes vous sûrs de vouloir supprimer l'étiquette TAG_NAME",
    "tag_list_is_empty": "Aucune étiquette pour le moment",
    "page_new_product_title": "Nouveau produit",
    "product_field_name": "Nom du produit",
    "product_field_description": "Description",
    "product_field_code": "Code du produit",
    "product_field_sku": "SKU du produit",
    "product_field_size": "Tailles",
    "product_field_color": "Couleurs",
    "product_field_provider": "Fournisseur",
    "product_field_categories": "Catégories",
    "page_new_product": "AJouter un produits",
    "product_field_tags": "Tags",
    "product_field_regular_price": "Prix régulier",
    "product_field_sale_price": "Prix de vente",
    "product_field_cleaning": "Matière et entretien",
    "product_field_detail": "Détail du produit",
    "product_field_size_n_stroke": "Taille & coupe",
    "add_image_from_media": "Choisir une photo",
    "create_attribute_action": 'Annuler',
    "size_image_title": 'Dimension de l\'image',
    "size_image_cancel": 'Réinitialiser',
    "maximum_width": 'Largeur maximale',
    "maximum_height": 'Hauteur maximale',
    "empty_product_img_message": "Ajouter une image au produit",
    "btn_add_product": "Créer le produit",
    "dialog_delete_product_title": "Supprimer un produit",
    "dialog_delete_product_description": "Voulez vous vraiment supprimer ce produits? Cette action est irreversible !",
    "supplier_delivring_time":"Jour(s)",
    "supplier_delivring_hours":"Heure(s)",
    "detail_product_page_title": "Détail d'un produit",
    "detail_product_page_tab_cleaning": "Matière et entretien",
    "detail_product_page_tab_detail": "Détail du produit",
    "detail_product_page_tab_size_and_stroke": "Taille & coupe",
    "detail_product_page_label_online": "En ligne",
    "confirm_coded_label": "Enter verification code",
    "confirm_code_title": "Account confirmation",
    "confirm_code_subtitle": "we have sent you a code in your mailbox check your mail",
    "confirm_code_caption": "please enter the code you received by email",
    "confirm_code_action": "Confirm",
    "confirm_code_retry": "Resend email ?",
    "welcome_page_title": "Choose your location",
    "welcome_page_continue": "Continue",
    "welcome_page_subtitle": "open your account\nand lorem ipsum",
    "detail_product_page_label_offline": "Hors ligne",

  }
};
