// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => < SvgIconStyle src = { `/static/icons/navbar/${name}.svg` }
sx = {
    { width: 1, height: 1 }
}
/>;

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: getIcon('ic_banking'),
    booking: getIcon('ic_booking'),
    invoice: getIcon('ic_invoice'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    menuItem: getIcon('ic_menu_item'),
    media: getIcon('ic_media'),
    peoples: getIcon('ic_people'),
    settings: getIcon('ic_settings'),
    product: getIcon('ic_product'),
    tag: getIcon('ic_tag'),
    category: getIcon('ic_category'),
};

const navConfig = [{
        subheader: 'menu_group_general',
        items: [
            { title: 'menu_item_app', path: "/app/home", icon: ICONS.dashboard },
        ],
    },
    {
        subheader: 'ecommerce',
        items: [{
                title: 'menu_item_products',
                path: "/app/products",
                icon: ICONS.product,
                children: [
                    { title: 'menu_item_products', path: "/app/products" },
                    { title: 'menu_item_supplier', path: "/app/products/supplier" },
                    { title: 'menu_item_categories', path: "/app/products/categories" },
                    { title: 'menu_item_tags', path: "/app/products/tags" },
                    { title: 'menu_item_coupon_code', path: "/app/products/discounts" }
                ],
            },
            {
                title: 'menu_item_orders',
                path: "/app/orders",
                icon: ICONS.cart,
            },
            {
                title: 'menu_item_statistics',
                path: "/app/statistics",
                icon: ICONS.analytics,
            },
            {
                title: 'menu_item_media',
                path: "/app/medias",
                icon: ICONS.media,
            }
        ],
    },
    {
        subheader: 'management',
        items: [{
                title: 'menu_item_newsletters',
                path: "/app/newsletters",
                icon: ICONS.mail,
            },
            {
                title: 'menu_item_users',
                path: "/app/users",
                icon: ICONS.peoples,
            },
            {
                title: 'menu_item_settings',
                path: "/app/settings",
                icon: ICONS.settings,
            },
            // {
            //     path: '/app/categories',
            //     title: 'menu_item_categories',
            //     icon: ICONS.category,
            // },
            // {
            //     path: '/app/sub_categories',
            //     title: 'menu_item_sub_categories',
            //     icon: ICONS.tag,
            // },
        ],
    },


];

export default navConfig;