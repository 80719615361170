import {Pagination, UsersRange} from "store/types";
import {ProductSubCategory} from "../../store/types/ProductsTypes";

// export const prefixer = 'http://192.168.83.12:8500/api/v1/';
export const prefixer = 'https://api.develop.pkf-mandantenportal.de/api/v1/';
// export const prefixer = 'https://api.develop.suitsmen.fr/api/v1/';
// export const prefixer = 'http://api.develop.suitsmen.fr:8000/api/v1/';

export const AuthUrls = {
  READ_TAGS: `${prefixer}auth/login`,
  UPDATE_USER_INFO: `${prefixer}auth/update`,
  UPDATE_PASSWORD: `${prefixer}auth/update-password`,
  LOGIN_USER: `${prefixer}auth/login`,
  CONFORM_LOGIN_USER: (code: string) => `${prefixer}auth/login-validate/${code}`,
  GET_CONNECTED_INFO: `${prefixer}auth/me`,
  SEND_EMAIL_RESET_PWD: `${prefixer}auth/password-recovery`,
  SEND_NEW_PWD: `${prefixer}auth/reset-password`,
};


export const GlobalUrl = {
  GET_GLOBAL_DATA: `${prefixer}users/default/data`,
};

