import {ADD_MEDIATHEQUE, ApplicationAction, DELETE_MEDIATHEQUE, MediaState, SET_MEDIATHEQUE_LIST} from 'store/types';
import SecureLS from 'secure-ls';

const ls = new SecureLS({encodingType: 'aes'});
let mediatheque;

try {
  mediatheque = !!ls.get('mediatheque') ? ls.get('mediatheque') : undefined;
} catch (error) {
}


const initialState: MediaState = {
  pagination: {}
}


const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {

    case DELETE_MEDIATHEQUE: {
      const data = {
        ...state,
        response: {
          ...state.response,
          data: (state?.response?.data ?? []).filter(m => m.uuid !== action.uuid)
        }
      }
      ls.set('mediatheque', data);

      return data
    }

    case ADD_MEDIATHEQUE: {
      const data = {
        ...state,
        response: {
          ...state.response,
          data: [action.data, ...(state?.response?.data ?? [])]
        }
      }

      return data
    }

    case SET_MEDIATHEQUE_LIST: {
      ls.set('mediatheque', action.data);
      return action.data
    }
    default: {
      return state;
    }
  }
};

export default reducer;
