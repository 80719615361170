import {default as React, useEffect, useRef, useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ThemeProvider, useTheme} from '@mui/material/styles';
import SecureLS from 'secure-ls';
import {toBase64} from "utils/toBase64";

import {MediaState, Mediatheque} from "store/types";
import {MediaService} from "../services/api";
import {MultiFilePreview} from "./upload";


const ls = new SecureLS({encodingType: 'aes'});


interface UploadMediaToolImplementProps {
  data: {
    urls: string[],
    alignItems: 'center' | 'flex-start' | 'flex-end' | "stretch",
  }
  onChange: (data: Partial<UploadMediaToolImplementProps["data"]>) => void
}


export default function UploadMediaTool(props: UploadMediaToolImplementProps) {
  const {t} = useTranslation();
  const [mediaOpen, setMediaOpen] = useState(false);
  const [urls, setUrls] = useState<string[]>(props.data.urls);
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const data = props.data;


  const onClose = () => {
    setMediaOpen(false);
  }
  let mediatheque: MediaState | undefined;
  let initialMode = ls.get('palette_mode') || 'light';

  try {
    mediatheque = !!ls.get('mediatheque') ? ls.get('mediatheque') : undefined;
  } catch (error) {
  }
  const handleSubmit = (payload: any) => {
    setLoading(true);
    // MediaService.add_mediatheque(payload)
    //   .then(async (res: any) => {
    //     const status = res.status === 200 || res.status === 201;
    //     if (status) {
    //       let data: Mediatheque = await res.json();
    //       props.onChange({
    //         urls: [(data?.url ?? ""), ...urls],
    //         alignItems: 'flex-start'
    //       });
    //       setMediaOpen(false);
    //       setUrls([(data?.url ?? ""), ...urls]);
    //     } else {
    //       let {detail, message} = await res.json();
    //     }
    //   })
    //   .catch((err: any) => {
    //     // enqueueSnackbar(t('shared.internet_connexion_error'), {
    //     //     variant: 'error'
    //     // });
    //   })
    //   .finally(() => setLoading(false));
  }

  const theme = useTheme();


  useEffect(() => {
    setUrls(props.data.urls ?? [])
  }, [props]);

  return (
    <div>
      <ThemeProvider theme={theme}>

        {
          urls.length > 0 &&
            <MultiFilePreview
                urls={urls} showPreview
                onRemove={(url) => {
                  setUrls(urls.filter(item => item != url));
                  props.onChange({
                    urls: urls.filter(item => item != url),
                    alignItems: 'flex-start'
                  })
                }
                }/>
        }

        <Box mt={1}>
          <input
            onChange={async (e) => {
              const files = e.target.files
              if (files && (files.length || 0) > 0) {
                const file = files.item(0);
                if (file) {
                  const imageBase64 = await toBase64(file);
                  handleSubmit({
                    file_name: file.name,
                    base_64: imageBase64.toString(),
                  });
                }

              }

            }}
            ref={inputRef} type="file" style={{display: 'none'}}/>
          <Box
            sx={{
              mt: 1,
              display: 'flex',
              padding: '24px',
              cursor: 'pointer',
              color: theme.palette.secondary.main,
              borderRadius: '10px',
              margin: '16px 0'
            }}
            onClick={() => {
              setMediaOpen(true)
            }}
            // className={classes.button}
          >

            <Typography>
              {t('add_image_from_media')}
            </Typography>
          </Box>
        </Box>
        <Dialog fullWidth maxWidth={'md'} open={mediaOpen} onClose={onClose}>
          <DialogTitle>
            {t('add_image_from_media')}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} alignItems={'stretch'}>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  onClick={() => {
                    if (!loading)
                      inputRef?.current?.click();
                  }}
                  sx={{
                    border: `2px dashed #DDDDDD`,
                    padding: '16px 16px',
                    outline: 'none',
                    position: 'relative',
                    height: '200px',
                    borderRadius: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                      opacity: 0.5,
                      cursor: 'pointer'
                    }
                  }}>
                  {loading ? <CircularProgress/> :
                    <Typography variant="body1" align={'center'} gutterBottom
                                color={'textSecondary'}>
                      {t('asset_drop_zone_label')}
                    </Typography>}
                </Box>
              </Grid>
              {
                (mediatheque?.response?.data || []).filter(i => !urls.includes(i?.url ?? ''))
                  .map(media => <Grid item xs={12} sm={6} md={4} key={media.uuid}>
                    <Box
                      onClick={() => {
                        setUrls([media?.url ?? "", ...urls]);
                        props.onChange({
                          urls: [media?.url ?? "", ...urls],
                          alignItems: 'flex-start'
                        });
                        setMediaOpen(false);
                      }}
                      sx={{
                        height: '200px',
                        position: 'relative',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        cursor: urls.includes(media?.url ?? "") ? 'default' : 'pointer',
                        '& img': {
                          height: '100%',
                          width: '100%',
                          objectFit: 'cover'
                        }
                      }}>
                      <img src={media.url} alt=""/>
                    </Box>
                  </Grid>)
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant={'contained'}>
              {t('create_attribute_action')}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
;
