import {
  CREATE_PRODUCT_SUCCESS, CREATE_SUPPLIER_SUCCESS, DELETE_PRODUCT_SUCCESS, DELETE_SUPPLIER_SUCCESS, DISPLAY_PRODUCT_SUCCESS,
  ProductState,
  READ_PRODUCTS_SUCCESS,
  READ_SUPPLIER_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_SUPPLIER_SUCCESS
} from "../types/ProductsTypes";
import {ApplicationAction} from "../types";
import produce from 'immer';
import {displayProductSuccess} from "../actions/ProductsActions";

const initialState: ProductState = {
  categories: {
    data: [],
    current_page: 1,
    pages: 1,
    total: 0,
    per_page: 20,
  },
  suppliers: {
    data: [],
    current_page: 1,
    pages: 1,
    total: 0,
    per_page: 20,
  },
  tags: {
    data: [],
    current_page: 1,
    pages: 1,
    total: 0,
    per_page: 20,
  },
  subcategories: {
    data: [],
    current_page: 1,
    pages: 1,
    per_page: 20,
    total: 0,

  },
  products: {
    data: [],
    current_page: 1,
    pages: 1,
    per_page: 20,
    total: 0,
  },
  product_detail: null
}


const reducer = (state = initialState, action: ApplicationAction) => {
  switch (action.type) {
    case "CREATE_PRODUCT_CATEGORY_SUCCESS":
      return produce(state, draft => {
        draft.categories.data.push(action.category)
      });
    case "READ_PRODUCT_CATEGORY_SUCCESS":
      return produce(state, draft => {
        draft.categories = action.categories
      });
    case "UPDATE_PRODUCT_CATEGORY_SUCCESS":
      return produce(state, draft => {
        draft.categories.data = draft.categories.data.map((cat) => cat.id == action.category.id ? action.category : cat);
      })
    case "DELETE_PRODUCT_CATEGORY_SUCCESS":
      return produce(state, draft => {
        draft.categories.data = draft.categories.data.filter(cat => cat.id != action.id);
      })

    case "CREATE_PRODUCT_TAG_SUCCESS":
      return produce(state, draft => {
        draft.tags.data.push(action.tag)
      });
    case "READ_PRODUCT_TAG_SUCCESS":
      return produce(state, draft => {
        draft.tags = action.tags
      });
    case "UPDATE_PRODUCT_TAG_SUCCESS":
      return produce(state, draft => {
        draft.tags.data = draft.tags.data.map((tag) => tag.id == action.tag.id ? action.tag : tag);
      })
    case "DELETE_PRODUCT_TAG_SUCCESS":
      return produce(state, draft => {
        draft.tags.data = draft.tags.data.filter(tag => tag.id != action.id);
      })

    case "CREATE_PRODUCT_SUB_CATEGORY_SUCCESS":
      return produce(state, draft => {
        if (state.categories.pages == state.categories.current_page) {
          draft.subcategories.data.push(action.sub_category)
        }
      });
    case "READ_PRODUCT_SUB_CATEGORY_SUCCESS":
      return produce(state, draft => {
        draft.subcategories = action.sub_category
      });
    case "UPDATE_PRODUCT_SUB_CATEGORY_SUCCESS":
      return produce(state, draft => {
        draft.subcategories.data = draft.subcategories.data.map((cat) => cat.id == action.sub_category.id ? action.sub_category : cat);
      })
    case "DELETE_PRODUCT_SUB_CATEGORY_SUCCESS":
      return produce(state, draft => {
        draft.categories.data = draft.categories.data.filter(cat => cat.id != action.id);
      })
    case READ_PRODUCTS_SUCCESS:
      return produce(state, draft => {
        draft.products = action.products;
      })
    case CREATE_PRODUCT_SUCCESS:
      return produce(state, draft => {
        draft.products.data = [action.product, ...state.products.data];
      })
    case UPDATE_PRODUCT_SUCCESS:
      return produce(state, draft => {
        draft.products.data = state.products.data.map(item => item?.uuid !== action.product.uuid ? item : action.product)
      })
    case DELETE_PRODUCT_SUCCESS:
      return produce(state, draft => {
        draft.products.data = state.products.data.filter(item => item?.uuid !== action.uuid)
      })
    case DISPLAY_PRODUCT_SUCCESS:
      return produce(state, draft => {
        draft.product_detail = action.product
      })


      case READ_SUPPLIER_SUCCESS:
        return produce(state, draft => {
          draft.suppliers = action.suppliers; 
        })
      case CREATE_SUPPLIER_SUCCESS:
        return produce(state, draft => {
          draft.suppliers.data = [action.supplier, ...state.suppliers.data];
        })
      case UPDATE_SUPPLIER_SUCCESS:
        return produce(state, draft => {
          draft.suppliers.data = state.suppliers.data.map(item => item?.uuid !== action.supplier.uuid ? item : action.supplier)
        })
      case DELETE_SUPPLIER_SUCCESS:
        return produce(state, draft => {
          draft.suppliers.data = state.suppliers.data.filter(item => item?.uuid !== action.uuid)
        })
     
        
    default:
      return state;

  }
}

export default reducer;
