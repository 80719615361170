import {combineReducers, Reducer} from 'redux';
import GlobalDataReducer from './reducers/GlobalDataReducer';
import {ApplicationState} from './types';
import InternationalisationReducer from "./reducers/InternationalisationReducer";
import AccountReducer from "./reducers/AccountReducer";
import UsersReducer from './reducers/UsersReducer';
import MediaReducer from "./reducers/MediaReducer";
import ProductReducer from "./reducers/ProductsReducer";

const rootReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  account: AccountReducer,
  globalData: GlobalDataReducer,
  i18n: InternationalisationReducer,
  users: UsersReducer,
  medias: MediaReducer,
  products: ProductReducer,
});

export default rootReducer;
