import {ApplicationAction, ApplicationState, Mediatheque, Pagination, SnackbarStatic} from "../types";
import {ThunkAction} from "redux-thunk";
import {addMediathequeAction, deleteMediathequeAction, setMediathequeListAction} from "../actions/MediaActions";
import {MediaService} from "services/api";

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;

export const add_mediatheque_effect = (
  payload: any,
  enqueueSnackbar: SnackbarStatic,
  setLoading: (loading: boolean) => void,
  onSuccess: (data: Mediatheque) => void,
): Effect => async (
  dispatch,
  getState
) => {
  const {i18n: {t = (key: string) => key}} = getState();
  setLoading(true)
  // return MediaService.add_mediatheque(payload)
  //   .then(async (res: any) => {
  //     const status = res.status === 200 || res.status === 201;
  //     if (status) {
  //       let data = await res.json();
  //       enqueueSnackbar(t('mediatheque_add_success'), {
  //         variant: 'success'
  //       });
  //       dispatch(addMediathequeAction(data));
  //       onSuccess(data);
  //     } else {
  //       let {detail, message} = await res.json();
  //       enqueueSnackbar(message || detail, {
  //         variant: 'warning'
  //       });
  //     }
  //   })
  //   .catch((err: any) => {
  //     enqueueSnackbar(t('shared.internet_connexion_error'), {
  //       variant: 'error'
  //     });
  //   })
  //   .finally(() => setLoading(false))
};


export const delete_mediatheque_effect = (
  uuid: string,
  enqueueSnackbar: SnackbarStatic,
  setLoading: (loading: boolean) => void,
  onSuccess: () => void,
): Effect => async (
  dispatch,
  getState
) => {
  const {i18n: {t = (key: string) => key}} = getState();
  setLoading(true)
  // return MediaService.delete_mediatheque(uuid)
  //   .then(async (res: any) => {
  //     const status = res.status === 200 || res.status === 201;
  //     if (status) {
  //       let data = await res.json();
  //       enqueueSnackbar(t('mediatheque_delete_success'), {
  //         variant: 'success'
  //       });
  //       dispatch(deleteMediathequeAction(
  //         uuid
  //       ))
  //       onSuccess();
  //     } else {
  //       let {detail, message} = await res.json();
  //       enqueueSnackbar(message || detail, {
  //         variant: 'warning'
  //       });
  //     }
  //   })
  //   .catch((err: any) => {
  //     enqueueSnackbar(t('shared.internet_connexion_error'), {
  //       variant: 'error'
  //     });
  //   })
  //   .finally(() => setLoading(false))
};

export const list_mediatheque_effect = (
  pagination: Pagination,
  setError: (msg?: string) => void,
  setLoading: (loading: boolean) => void,
): Effect => async (
  dispatch,
  getState
) => {
  const {i18n: {t = (key: string) => key}} = getState();
  setError(undefined);
  setLoading(true)
  // return MediaService.get_mediatheque(pagination)
  //   .then(async (response: any) => {
  //     if (response.status === 200 || response.status === 201) {
  //       const data = await response.json();
  //       dispatch(setMediathequeListAction({
  //         pagination,
  //         response: data
  //       }))
  //     } else {
  //       const {detail, message} = await response.json();
  //       setError(detail || message);
  //     }
  //   })
  //   .catch(async () => {
  //     setError(t('shared.internet_connexion_error_long'));
  //   })
  //   .finally(() => setLoading(false))
};
