import { mocked } from 'utils';
import {
  connected_user,
  list_users,
} from 'services/fixtures';

class UsersServiceMock {
  static get_connected_user = () => {
    return mocked(200, connected_user);
  };

  static list_users = () => mocked(200, list_users);
}

export default UsersServiceMock;
