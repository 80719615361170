import {Action} from 'redux';
import {TitleI18N, User} from './AccountTypes';

export const LOAD_USERS_REQUEST = 'LOAD_USERS_REQUEST';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_ERROR = 'LOAD_USERS_ERROR';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_REQUEST_ENDED = 'UPDATE_USER_REQUEST_ENDED';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_REQUEST_ENDED = 'ADD_USER_REQUEST_ENDED';

export interface UsersRange {
  page: number;
  per_page: number;
  keyword?: string;
  role_uuid?: string;
}

export interface UserRole {
  id?: number;
  title_fr?: string;
  lang_i18n?: TitleI18N;
  date_added?: string;
  date_modified?: string;
}

export interface LoginResponse {
  status: string;
  message: string;
  Authorization: string;
  data: User;
}

export interface LoginAgencyResponse {
  status: string;
  message: string;
  Authorization: string;
  data: LoginAgencyData;
}

export interface LoginAgencyData {
  created_date: string;
  uuid: string;
  user: User;
}

export interface LoadingUsersState {
  users: boolean;
}

export interface UserData {
  data: User[];
  total: number;
  pages: number;
  current_page: number;
}

export interface UsersState {
  loading: LoadingUsersState;
  data: UserData;
  range: UsersRange;
}

export interface LoadUsersRequest extends Action {
  type: typeof LOAD_USERS_REQUEST;
  range: UsersRange;
}

export interface LoadUsersSuccess extends Action {
  type: typeof LOAD_USERS_SUCCESS;
  data: UserData;
}

export const DELETE_USER = 'DELETE_USER';

export interface DeleteUserAction extends Action {
  type: typeof DELETE_USER,
  data: DeleteUserActionParams
}

export interface DeleteUserActionParams {
  uuid: string
}

export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS';

export interface ChangeUserStatusAction extends Action {
  type: typeof CHANGE_USER_STATUS,
  data: ChangeUserStatusActionParams
}

export interface ChangeUserStatusActionParams {
  // uuid: string,
  data: User
}

export interface LoadUsersError extends Action {
  type: typeof LOAD_USERS_ERROR;
}

export interface AddUserRequest extends Action {
  type: typeof ADD_USER_REQUEST;
}

export interface AddUserEnded extends Action {
  type: typeof ADD_USER_REQUEST_ENDED;
}

export interface UpdateUserRequest extends Action {
  type: typeof UPDATE_USER_REQUEST;
}

export interface UpdateUserRequestEnded extends Action {
  type: typeof UPDATE_USER_REQUEST_ENDED;
}

export const ADD_USER = 'ADD_USER';

export interface AddUserAction extends Action {
  type: typeof ADD_USER,
  data: AddUserActionParams
}

export interface AddUserActionParams {
  data: User
}

export const UPDATE_USER = 'UPDATE_USER';

export interface UpdateUserAction extends Action {
  type: typeof UPDATE_USER,
  data: User
}
