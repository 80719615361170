import {ThunkAction} from 'redux-thunk';
import {ApplicationAction, ApplicationState, SnackbarStatic, UpdateUserProps, User, UsersRange} from 'store/types';
import {deleteUserAction, loadUsersError, loadUsersSuccess} from 'store/actions';
import {addUserAction, changeStatusUserAction, updateUserAction} from 'store/actions/UserActions'
import {UserService} from 'services/api';

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;

export const loadUsers = (range: UsersRange, setError: (message?: string) => void, setLoading: Function): Effect => async (
  dispatch,
  getState
) => {

  const {i18n: {t = (key: string) => key}} = getState();
  setLoading(true)

};

export const add_user_effect = (
  payload: Partial<User>,
  enqueueSnackbar: SnackbarStatic,
  setLoading: (loading: boolean) => void,
  onSuccess: () => void,
): Effect => async (
  dispatch,
  getState
) => {
  const {i18n: {t = (key: string) => key}} = getState();
  setLoading(true)
};
export const update_user_effect = (
  payload: UpdateUserProps,
  enqueueSnackbar: SnackbarStatic,
  setLoading: (loading: boolean) => void,
  onSuccess: () => void,
): Effect => async (
  dispatch,
  getState
) => {
  const {i18n: {t = (key: string) => key}} = getState();
  setLoading(true)
};
export const delete_user_effect = (
  uuid: string,
  enqueueSnackbar: SnackbarStatic,
  setLoading: (loading: boolean) => void,
  onSuccess: () => void,
): Effect => async (
  dispatch,
  getState
) => {
  const {i18n: {t = (key: string) => key}} = getState();
  setLoading(true)
};


export const change_user_status_effect = (
  payload:any,
  enqueueSnackbar: SnackbarStatic,
  setLoading: (loading: boolean) => void,
  onSuccess: () => void,
): Effect => async (
  dispatch,
  getState
) => {
  const {i18n: {t = (key: string) => key}} = getState();
  setLoading(true)
};


