import React, {useState} from 'react';
import {ErrorHandler} from 'components';
import {Box} from "@mui/material";
import DashboardHeader from "./header";
import useSettings from "../../hooks/useSettings";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import NavbarVertical from "./navbar/NavbarVertical";
import {HEADER, NAVBAR} from "../../settings/config";
import {styled} from "@mui/material/styles";


const MainStyle = styled('main', {shouldForwardProp: (prop) => prop !== 'collapseClick'})
(({
    // @ts-ignore
    collapseClick, theme
  }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

const Application = (props: any) => {
  const {children} = props;
  const {collapseClick, isCollapse} = useCollapseDrawer();

  const {themeLayout} = useSettings();
  const [open, setOpen] = useState(false);


  return (
    <Box
      sx={{
        display: {lg: 'flex'},
        minHeight: {lg: 1},
      }}
    >
      <ErrorHandler>
        <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)}/>
      </ErrorHandler>
      <ErrorHandler>
        <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => {
          setOpen(false)
        }}/>
      </ErrorHandler>
      <MainStyle collapseClick={collapseClick}>
        <Box
          component="main"
        >
          {children}
        </Box>
      </MainStyle>
    </Box>
  );
};

export default Application;
