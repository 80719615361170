import {GlobalDataState} from "../../store/types/GlobalDataType";


export const default_global_data: GlobalDataState = {
  "roles": [
    {
      "uuid": "0834d154-30b5-4faa-9028-463ccf9a48ba",
      "name": "Administrator",
      "code": "administrator"
    },
    {
      "uuid": "726a55c2-c6fb-41f7-9fd5-3224ec807f38",
      "name": "Mitarbeiter",
      "code": "collaborater"
    },
    {
      "uuid": "3a1e6b67-4f8e-4e38-92a8-c18dad22c0d7",
      "name": "Gesprächspartner",
      "code": "interlocutor"
    }
  ],
  "status": [
    "UNVALIDATED",
    "ACTIVED",
    "UNACTIVED",
    "DELETED"
  ],
  "sites": [
    {
      "uuid": "be5ff84a-d50a-4d68-9962-1233f1767014",
      "localisation": "Löffelstraße 44, 70597 Stuttgart",
      "date_added": "2022-06-03T11:32:29.213276+00:00",
      "added_by_uuid": "dc128b00-6f49-4075-a085-be2d12bdd54a",
      "name": "PKF WULF & PARTNER Partnerschaft mbB",
      "group_uuid": null,
      "date_modified": "2022-06-03T11:32:29.213332+00:00",
      "modified_by_uuid": null
    },
    {
      "uuid": "dfaf9ea1-f963-43eb-9eb4-a5cebde88ee2",
      "localisation": "Tegernaustraße 7, 72336 Balingen",
      "date_added": "2022-06-03T11:32:29.213276+00:00",
      "added_by_uuid": "dc128b00-6f49-4075-a085-be2d12bdd54a",
      "name": "PKF WULF EGERMANN oHG Zollernalb Treuhand",
      "group_uuid": null,
      "date_modified": "2022-06-03T11:32:29.213332+00:00",
      "modified_by_uuid": null
    },
    {
      "uuid": "012d031c-0d34-4485-a4a9-14427e7e2627",
      "localisation": "Im Neuenbühl 7, 71287 Weissach",
      "date_added": "2022-06-03T11:32:29.213276+00:00",
      "added_by_uuid": "dc128b00-6f49-4075-a085-be2d12bdd54a",
      "name": "PKF WULF BURR KG",
      "group_uuid": null,
      "date_modified": "2022-06-03T11:32:29.213332+00:00",
      "modified_by_uuid": null
    },
    {
      "uuid": "fde54891-2c7d-4c79-b2e6-d2acf65182ea",
      "localisation": "Löffelstraße 44, 70597 Stuttgart",
      "date_added": "2022-06-03T11:32:29.213276+00:00",
      "added_by_uuid": "dc128b00-6f49-4075-a085-be2d12bdd54a",
      "name": "PKF WULF RAGER KG",
      "group_uuid": null,
      "date_modified": "2022-06-03T11:32:29.213332+00:00",
      "modified_by_uuid": null
    },
    {
      "uuid": "b215eeb2-d924-4423-a7c2-fd14c52eb4cc",
      "localisation": "Löffelstraße 44, 70597 Stuttgart",
      "date_added": "2022-06-03T11:32:29.213276+00:00",
      "added_by_uuid": "dc128b00-6f49-4075-a085-be2d12bdd54a",
      "name": "PKF WULF KURFESS KG",
      "group_uuid": null,
      "date_modified": "2022-06-03T11:32:29.213332+00:00",
      "modified_by_uuid": null
    },
    {
      "uuid": "73a406a7-bbb2-4d6e-9310-657b8c57ec82",
      "localisation": "Im Breitspiel 11, 69126 Heidelberg",
      "date_added": "2022-06-03T11:32:29.213276+00:00",
      "added_by_uuid": "dc128b00-6f49-4075-a085-be2d12bdd54a",
      "name": "PKF Riedel Appel Hornig GmbH",
      "group_uuid": null,
      "date_modified": "2022-06-03T11:32:29.213332+00:00",
      "modified_by_uuid": null
    }
  ],
  "groups": [
    {
      "ceo_name": "PKF WULF GRUPPE",
      "uuid": "a15a19ad-ca43-4a43-9a79-c903be74bc5d",
      "name": "PKF WULF GRUPPE"
    },
    {
      "ceo_name": "PKF Riedel Appel Hornig GmbH",
      "uuid": "72f29bbd-e142-4207-a1b3-adf6071f2c86",
      "name": "PKF Riedel Appel Hornig GmbH"
    }
  ]
}
