import {ThunkAction} from 'redux-thunk';
import {ApplicationAction, GlobalDataState} from 'store/types';
import GlobalService from "services/api/GlobalService";
import {setGlobalDataAction} from "../actions/GlobalDataAction";

type Effect = ThunkAction<any, GlobalDataState, any, ApplicationAction>; //! write documentation here for this line

export const loadGlobalDataEffect = (): Effect => async (
  dispatch,
  getState
) => {
  return GlobalService.load_global_data()
    .then(async (response: any) => {
      if (response.status === 200) {
        dispatch(setGlobalDataAction({
            data: await response.json()
        }));
      }
    })
    .catch(() => {});
};

