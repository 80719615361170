import React, {useRef} from 'react';
import {Provider} from 'react-redux';
import {jssPreset, StylesProvider,} from "@mui/styles";
import {create} from 'jss';
// import rtl from 'jss-rtl';
import CssBaseline from '@mui/material/CssBaseline';
import {SnackbarProvider} from 'notistack';
import store from 'store';
import Routes from 'routes';

import 'translations';
import Auth from 'routes/Auth';
import {StyledEngineProvider} from "@mui/styled-engine-sc";
import {IconButton, PaletteMode} from "@mui/material";
import SecureLS from 'secure-ls';
import {X} from "react-feather";

import NotistackProvider from './components/NotistackProvider';
import {MotionLazyContainer} from "./components/animate";
import ScrollToTop from "./components/ScrollToTop";
import {ProgressBarStyle} from "./components/ProgressBar";
import {ChartStyle} from "./components/chart";
import ThemeProvider from "./theme";
import {HelmetProvider} from "react-helmet-async";

const ls = new SecureLS({encodingType: 'aes'});


const jss = create({plugins: [...jssPreset().plugins,]});

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {
  }
});

const App = () => {

  let initialMode = ls.get('palette_mode') || 'light';

  const notistackRef = useRef<any>();

  const [mode, setMode] = React.useState<PaletteMode>(initialMode);
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = (prevMode === 'light' ? 'dark' : 'light');
          ls.set('palette_mode', newMode);
          return newMode;
        });
      },
    }),
    [],
  );

  const helmetContext = {};

  return (
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <HelmetProvider context={helmetContext}>
          <ColorModeContext.Provider value={colorMode}>
            <MotionLazyContainer>
              <NotistackProvider>
                <ThemeProvider>
                  <StylesProvider jss={jss}>
                    <React.Fragment>
                      <CssBaseline/>
                      <ProgressBarStyle/>
                      <ChartStyle/>
                      <ScrollToTop/>
                      <Auth>
                        {/*<ScrollReset />*/}
                        <Routes/>
                        {/*{config.env === 'DEV' && <MockedButton/>}*/}
                      </Auth>
                    </React.Fragment>
                  </StylesProvider>
                </ThemeProvider>
              </NotistackProvider>
            </MotionLazyContainer>
          </ColorModeContext.Provider>
        </HelmetProvider>
      </Provider>
    </StyledEngineProvider>
  );
};

export default App;
