import PropTypes from 'prop-types';
import {m} from 'framer-motion';
import {forwardRef} from 'react';
// @mui
import {Box, IconButton} from '@mui/material';

// ----------------------------------------------------------------------

interface IconButtonAnimateType {
  children: React.ReactNode;
  color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
  size?: 'small' | 'medium' | 'large';
  onClick?: any,
  sx?: object,
}

const IconButtonAnimate = forwardRef(({
                                        children,
                                        size = 'medium',
                                        onClick,
                                        sx,
                                        ...other
                                      }: IconButtonAnimateType, ref: any) => (
  <AnimateWrap size={size}>
    <IconButton onClick={onClick} size={size} ref={ref} {...other}>
      {children}
    </IconButton>
  </AnimateWrap>
));

export default IconButtonAnimate;

// ----------------------------------------------------------------------

const varSmall = {
  hover: {scale: 1.1},
  tap: {scale: 0.95}
};

const varMedium = {
  hover: {scale: 1.09},
  tap: {scale: 0.97}
};

const varLarge = {
  hover: {scale: 1.08},
  tap: {scale: 0.99}
};

AnimateWrap.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

interface AnimateWrapType {
  children: React.ReactNode,
  size: 'small' | 'medium' | 'large'
}

function AnimateWrap({size, children}: AnimateWrapType) {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: 'inline-flex'
      }}
    >
      {children}
    </Box>
  );
}
