import BaseService from './BaseService';
import {AuthUrls} from './urls';
import {login_mock} from "../mocks";

class AuthService {

    static get_userInfo = () => {
        return BaseService.postRequest(AuthUrls.GET_CONNECTED_INFO, {}, true);
    };

    static update_userInfo = (data: any) => {
        return BaseService.putRequest(AuthUrls.UPDATE_USER_INFO, data, true);
    };

    static update_password = (data: any) => {
        return BaseService.putRequest(AuthUrls.UPDATE_PASSWORD, data, true);
    };

    static login = (info: any) => {
        if ((window as any).mocked) {
            return login_mock(info);
        } else {
            return BaseService.postRequest(AuthUrls.LOGIN_USER, info, false);
        }
    };

    static confirmLogin = (code: string) => {
        return BaseService.putRequest(AuthUrls.CONFORM_LOGIN_USER(code), {}, false);
    };

    static sendEmailForResetPassword(email: string) {
        return BaseService.putRequest(
            AuthUrls.SEND_EMAIL_RESET_PWD,
            {email},
            false
        );
    }

    static sendNewPassword(payload: { email: string, code: string, new_password: string }) {
        return BaseService.putRequest(
            AuthUrls.SEND_NEW_PWD,
            payload,
            false
        );
    }
}

export default AuthService;
