import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {SplashScreen} from 'components';
import {setTranslationFunction} from 'store/actions';
import {useTranslation} from 'react-i18next';
import {createStyles, makeStyles} from "@mui/styles";
import {loadGlobalDataEffect} from "../store/effects/GlobalDataEffects";
import SecureLS from 'secure-ls';
import {list_mediatheque_effect} from "store/effects";

const ls = new SecureLS({encodingType: 'aes'});
let token: undefined | string, user;

try {
  token = !!ls.get('token') ? ls.get('token') : undefined;
} catch (error) {
}


const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      a: {
        textDecoration: 'none!important'
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%',
        //TODO Find best solution to fix it
        // "&> iframe": {
        //   display: 'none'
        // }
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

function Auth(props: any) {
  const {children} = props;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const {t} = useTranslation();
  useStyles();
  useEffect(() => {
    if (token) {
      dispatch(list_mediatheque_effect({
        page: 1,
        per_page: 50
      }, () => {
      }, () => {
      }));
    }
    dispatch(setTranslationFunction(t));
    dispatch(loadGlobalDataEffect());
  }, [dispatch, t]);

  if (isLoading) {
    return <SplashScreen/>;
  }

  return children;
}

export default Auth;
