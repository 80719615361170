import {useEffect, useState} from 'react';
import {enUS, frFR} from '@mui/material/locale';

import {MenuItem, Stack} from '@mui/material';
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import {IconButtonAnimate} from '../../../components/animate';
import {useTranslation} from "react-i18next";
import SecureLS from "secure-ls";

const localStorage = new SecureLS({encodingType: 'aes'});


export default function LanguagePopover() {
    const {i18n} = useTranslation();

    const currentLang = i18n.language;

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleChangeLang = (newLang) => {
        if (!!newLang) {
            i18n.changeLanguage(newLang.toLowerCase());
            localStorage.set('language', newLang.toLowerCase());
        }
        handleClose();
    };

    useEffect(() => {
        i18n.changeLanguage(localStorage.get('language') || 'fr');
    }, [i18n]);


    return (
        <>
            <IconButtonAnimate
                onClick={handleOpen}
                sx={{
                    width: 40,
                    height: 40,
                    ...(open && {bgcolor: 'action.selected'}),
                }}
            >
                <Image disabledEffect src={`/static/icons/flags/ic_flag_${currentLang}.svg`} alt={currentLang}/>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 180,
                    '& .MuiMenuItem-root': {px: 1, typography: 'body2', borderRadius: 0.75},
                }}
            >
                <Stack spacing={0.75}>
                    {
                        [
                            {
                                value: "en",
                                label: "English",
                                icon: '/static/icons/flags/ic_flag_en.svg',
                                systemValue: enUS,
                            },
                            {
                                value: "fr",
                                label: "Français",
                                icon: '/static/icons/flags/ic_flag_fr.svg',
                                systemValue: frFR,
                            },
                        ].map((option) => (
                            <MenuItem
                                key={option.value}
                                selected={option.value === i18n.language}
                                onClick={() => handleChangeLang(option.value)}
                            >
                                <Image disabledEffect alt={option.label} src={option.icon} sx={{width: 28, mr: 2}}/>

                                {option.label}
                            </MenuItem>
                        ))}
                </Stack>
            </MenuPopover>
        </>
    );
}
